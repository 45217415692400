import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../../components/social.js"

import Layout from "../../components/LayoutAlt.js"
import { FaArrowAltCircleLeft } from "react-icons/fa"

export default function Channel () {
    return<div> 
        <Layout>

        <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Get Bible Studies and much more in video format" />
        <title>ReaCH | Channel</title>
        <link rel="canonical" href="https://reachun.org/biblestudy/channel" />
      </Helmet>

        <section className="sm:w-3/4 sm:ml-1/8 xs:mt-10 sm:mt-20 mb-20">
        <div className="font-montserrat font-light text-blue-dark">
           <h1 className="xs:text-4xl sm:text-6xl md:text-7xl">
           ReaCh<span className="font-bold">Channel</span>
           </h1>
           <Link to="/biblestudy">
                <section className="font-opensans  text-white  bg-orange-dark xs:mt-12 sm:mt-20 lg:mt-5 xl:mt-10 py-2 pl-4 
                  rounded-r-xl flex items-center">
                    <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer"/> 
                    <p className=" text-xs sm:text-base">/biblestudies/channel</p>
                  </section>
            </Link>
           </div>
           <div className="font-playfairdisplay font-bold text-gray-700 xs:px-4 sm:px-0">
                <p className="xs:mt-10 md:mt-20  xs:text-5xl ">Wer're Sorry, there are no Videos at the moment.  </p>
                <p className="mt-5 text-4xl ">Stay Tuned for upcoming Video Presentation.</p>
           </div>
        </section>
        <Social />
        </Layout>   
    </div>

}